import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store('consent', {});

Alpine.data('cookieNotice', (config) => ({
    showList: false,
    tab: 'overview',
    init() {
        document.body.style.overflow = 'hidden';
        const consent = window.CookieNotice.cookieExists(config.cookie_name) ? null : false;
        config.consent_groups.forEach(group => this.$store.consent[group.handle] = consent);
        window.CookieNotice.on('accepted', (group) => { this.$store.consent[group] = true, document.body.style.removeProperty('overflow') });
        window.CookieNotice.on('declined', (group) => { this.$store.consent[group] = false, document.body.style.removeProperty('overflow') });;
        window.CookieNotice.boot(this.$root, config);
        this.$dispatch('cookie-notice-load');
    },
    show() {
        window.CookieNotice.showWidget();
        document.body.style.overflow = 'hidden';
    },
    hide() {
        window.CookieNotice.hideWidget();
        document.body.style.removeProperty('overflow');
    },
    acceptAll() {
        window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
            if (window.CookieNotice && window.CookieNotice.widget) {
                window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
            } else {
                console.warn('window.CookieNotice.widget not found');
            }
        });

        window.CookieNotice.savePreferences();

        this.hide();
        this.$dispatch('cookie-decided', { decision: 'accepted-all' });
    },
    acceptNecessary() {
        window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
          if(consentGroup.handle === 'necessary') {
            window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
          } else {
            window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = false;
          }
        });
        window.CookieNotice.savePreferences();
        this.hide();
        this.$dispatch('cookie-decided', { decision: 'accepted-necessary' });
    },
    acceptSelected() {
        window.CookieNotice.savePreferences();
        this.hide();
        this.$dispatch('cookie-decided', { decision: 'accepted-selected' });
    },
    setTab(tabName) {
        this.tab = tabName;
        if (tabName === 'overview') this.showList = false;
    }
}));

Alpine.data('consentGate', ({ category }) => ({
    consent: null,
    init() {
        Alpine.effect(() => {
            this.consent = this.$store.consent[category];
            this.$nextTick(() => {
                if (this.consent === true) {
                    this.executeScripts(this.$root.innerHTML);
                }
            });
        });
    },
    executeScripts(html) {
        document.createRange()
            .createContextualFragment(html)
            .querySelectorAll('script')
            .forEach(script => {
                document.body.appendChild(script.cloneNode(true));
            });
    },
}));
