import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('newsbroadcastplayer', ({
    uid,
    jwpMediaId,
    mp4,
    title,
    autoplay,
    image,
    isExternal,
    chromeless,
    loop,
    pauseWhenOthersPlay,
    pauseWhenNotVisible,
    floatOnScroll,
    hasClips,
    clipList,
    isLivebroadcast,
    isLivestream,
}) => ({
    uid: uid,
    jwpMediaId: jwpMediaId,
    mp4: mp4,
    title: title,
    autoplay: autoplay,
    image: image,
    isExternal: !!isExternal,
    chromeless: chromeless,
    loop: loop,
    pauseWhenOthersPlay: pauseWhenOthersPlay,
    pauseWhenNotVisible: pauseWhenNotVisible,
    floatOnScroll: floatOnScroll,
    hasClips: hasClips,
    clipList: clipList,
    isLivebroadcast: !!isLivebroadcast,
    isLivestream: !!isLivestream,
    displaySideBar: true,
    player: null,

    init () {
        const startingHash = window.location.hash.substring(1);
        if (startingHash.length > 0) {
            const clip = this.clipList.find(e => e.jwp_media_id === startingHash);
            if (clip) {
                this.title = `${clip.title}`;
                this.jwpMediaId = clip.jwp_media_id;
            }
        }

        this.autoplay = this.autoplay === true;

        requestAnimationFrame(() => {
            const opts = this.jwpOptions();
            this.player = jwplayer(this.$refs.video).setup(opts);
        });
    },

    isIpadApp () {
        if (!/Median/i.test(navigator.userAgent)) return false;
        if (/iPad/i.test(navigator.userAgent)) return true;
        if (navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 1 &&
            /Intel Mac/i.test(navigator.userAgent)) return true;
        return false;
    },

    jwpOptions() {
        let opts = {
            aspectratio: '16:9',
            autoPause: { viewability: false },
            autostart: this.autoplay,
            controls: !this.chromeless,
            displaydescription: false,
            displayHeading: false,
            displaytitle: false,
            floating: this.floatOnScroll ? { dismissible: true } : { mode: 'never' },
            height: '100%',
            mute: false,
            preload: 'auto',
            repeat: this.loop,
            width: '100%',
        };

        const baseUrl = 'https://cdn.jwplayer.com';

        if (this.isExternal || this.isIpadApp()) {
            opts.file = `${baseUrl}/videos/${ this.jwpMediaId }.mp4`;
        } else {
            if (this.isLivebroadcast || this.isLivestream) {
                opts.playlist = `${baseUrl}/v2/media/${this.jwpMediaId}?format=json`;
            } else {
                opts.file = `${baseUrl}/manifests/${this.jwpMediaId}.m3u8`;
            }
        }

        if (this.image) {
            opts.image = `${this.image}`;

            if (this.isLivebroadcast || this.isLivestream) {
                if (!this.autoPlay) {
                    opts.playlist = '';

                    if (this.isLivebroadcast) {
                        opts.file = `${baseUrl}/live/broadcast/${this.jwpMediaId}.m3u8`;
                    } else if (this.isLivestream) {
                        opts.file = `${baseUrl}/live/events/${this.jwpMediaId}.m3u8`;
                    }
                }
            }
        } else {
            if (this.isIpadApp()) {
                opts.image = `${baseUrl}/v2/media/${this.jwpMediaId}/poster.jpg`;
            } else {
                opts.playlist = `${baseUrl}/v2/media/${this.jwpMediaId}?format=json`;
            }
        }

        return opts;
    },

    toggleSideBar () {
        this.displaySideBar = !this.displaySideBar;
    },

    playlistUrl () {
        const baseUrl = 'https://cdn.jwplayer.com';
        return `${baseUrl}/v2/media/${this.jwpMediaId}?format=json`;
    },

    loadVideo (jwpMediaId, title) {
        this.jwpMediaId = jwpMediaId;
        this.title = `${title}`;
        this.player.image = this.image;
        this.player.load(this.playlistUrl());
        this.player.play();
        this.player.setMute(false);
        this.scrollToPlayer();
        window.location.hash = jwpMediaId;
        document.title = `${title} | TV MIDTVEST`;
    },

    scrollToPlayer() {
        if (window.innerWidth < 768) {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 100);
        }
    }
}));
