import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import libs from "../frop/libs.js";

Alpine.data("video_player", ({
    uid,
    jwpMediaId,
    mp4,
    motionThumb,
    motionThumbSrc,
    customThumb,
    isExternal,
    autoPlay,
    mute,
    chromeless,
    cover,
    loop,
    pauseWhenOthersPlay,
    pauseWhenNotVisible,
    floatOnScroll,
    isLivebroadcast,
    isLivestream,
}) => ({
    uid: uid,
    jwpMediaId: jwpMediaId,
    mp4: mp4,
    motionThumb: motionThumb,
    motionThumbSrc: motionThumbSrc,
    customThumb: customThumb,
    isExternal: !!isExternal,
    isLivebroadcast: !!isLivebroadcast,
    isLivestream: !!isLivestream,
    autoPlay: autoPlay,
    mute: mute,
    chromeless: chromeless,
    cover: cover,
    loop: loop,
    pauseWhenOthersPlay: pauseWhenOthersPlay,
    pauseWhenNotVisible: pauseWhenNotVisible,
    floatOnScroll: floatOnScroll,
    API_URL: `https://cdn.jwplayer.com/v2/media/${ jwpMediaId }?format=json`,
    player: null,
    source: null,
    plausibleQueue: [],

    init() {
        if (this.mp4) {
            this.player = this.wrapHtmlPlayer();
        } else {
            const opts = this.jwOptions();
            this.player = jwplayer(this.$refs.video).setup(opts);
        }

        this.attachEvents();
        this.registerPlayer();
    },

    isIpadApp () {
        if (!/Median/i.test(navigator.userAgent)) return false;
        if (/iPad/i.test(navigator.userAgent)) return true;
        if (navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 1 &&
            /Intel Mac/i.test(navigator.userAgent)) return true;
        return false;
    },

    registerPlayer() {
        if (window.frop_players === undefined) window.frop_players = {};
        if (window.frop_players_pause === undefined) window.frop_players_pause = {};

        const cb = window.frop_players[this.uid];
        window.frop_players[this.uid] = this.player;

        if (typeof cb === "function") cb();
        if (this.pauseWhenOthersPlay) window.frop_players_pause[uid] = this.player;
    },

    wrapHtmlPlayer() {
        const player = { eventQueues: {} };

        player.on = (eventName, callBack) => {
            if (typeof player.eventQueues[eventName] === 'undefined') player.eventQueues[eventName] = [];
            player.eventQueues[eventName].push(callBack);
        }

        player.trigger = (eventName, data) => {
            if (typeof player.eventQueues[eventName] === 'undefined') return;
            player.eventQueues[eventName].forEach(e => e(data));
        }

        player.play = () => {
            player.trigger('play');
            this.$refs.html_video.play();
        }

        player.pause = () => {
            this.$refs.html_video.pause();
        }

        player.getMute = () => {
            return this.$refs.html_video.getAttribute('muted') === 'true';
        }

        player.setMute = (mute) => {
            if (typeof mute !== "boolean") throw new Error('setMute accepts only boolean values');
            player.trigger('mute', { 'mute': mute });
            this.$refs.html_video.setAttribute('muted', mute);
        }

        player.setVolume = (volume) => {
            if (typeof volume !== 'number') throw new Error('setVolume accepts only integers');
            if (volume < 0 || volume > 100) throw new Error('setVolume accepts only integers in the range 0-100 inclusive');

            volume = volume / 100;
            this.$refs.html_video.volume = volume;
        }

        player.seek = (time) => {
            this.$refs.html_video.currentTime = time;
        }

        return player;
    },

    jwOptions () {
        let opts = {
            autoPause: { viewability: false },
            autostart: this.autoPlay,
            controls: !this.chromeless,
            displaydescription: false,
            displayHeading: false,
            displaytitle: false,
            floating: this.floatOnScroll ? { dismissible: true } : { mode: 'never' },
            mute: this.mute,
            repeat: this.loop,
            preload: 'auto',
            responsive: false,
            stretching: this.cover ? 'fill' : 'uniform',
        };

        if (this.motionThumb && this.motionThumbSrc) this.source = this.motionThumbSrc;

        const baseUrl = 'https://cdn.jwplayer.com';

        if (this.isExternal || this.isIpadApp()) {
            opts.file = `${baseUrl}/videos/${ this.jwpMediaId }.mp4`;
        } else {
            if (this.isLivebroadcast || this.isLivestream) {
                opts.playlist = `${baseUrl}/v2/media/${this.jwpMediaId}?format=json`;
            } else {
                opts.file = `${baseUrl}/manifests/${this.jwpMediaId}.m3u8`;
            }
        }

        if (this.customThumb) {
            opts.image = `${location.protocol}//${location.host}${this.customThumb}`;

            if (this.isLivebroadcast || this.isLivestream) {
                if (!this.autoPlay) {
                    opts.playlist = '';

                    if (this.isLivebroadcast) {
                        opts.file = `${baseUrl}/live/broadcast/${this.jwpMediaId}.m3u8`;
                    } else if (this.isLivestream) {
                        opts.file = `${baseUrl}/live/events/${this.jwpMediaId}.m3u8`;
                    }
                }
            }
        } else {
            if (this.isIpadApp()) {
                opts.image = `${baseUrl}/v2/media/${this.jwpMediaId}/poster.jpg`;
            } else {
                opts.playlist = `${baseUrl}/v2/media/${this.jwpMediaId}?format=json`;
            }
        }

        return opts;
    },

    async loadMP4 () {
        if (this.source) return;
        const response = await fetch(this.API_URL);
        const json = await response.json();
        const width = this.$refs.video.offsetWidth;

        if (response.status === 200) {
            if (this.motionThumb && !this.motionThumbSrc) {
                const imageThumb = json.playlist[0].images.find(image => (image.width === 640)).src;
                this.source = imageThumb.replace('.jpg', '.mp4');
            } else {
                const mp4Sources = json.playlist[0].sources.filter(o => (o.type === 'video/mp4'));
                for (let i = 0; i < mp4Sources.length; i++) {
                    let videoFile = mp4Sources[i];
                    if (videoFile.width >= width || i == mp4Sources.length - 1) {
                        this.source = videoFile.file;
                        break;
                    }
                }
            }
        }
    },

    attachEvents () {
        this.player.on("play", this.play.bind(this));
    },

    play () {
        // this.sendPlausibleEvent('Video-Play');
        Object.keys(window.frop_players_pause).forEach((playerId) => {
            const player = window.frop_players_pause[playerId];
            if (playerId !== this.uid && player.getState() == "playing") {
                player.pause();
            }
        });
    },

    sendPlausibleEvent(eventName) {
        libs.plausible.then((plausible) => {
            plausible(eventName);
        });
    },

    intersectStart () {
        this.autoPlay && this.player.play();
    },

    intersectStop () {
        this.pauseWhenNotVisible && this.player.pause();
    }
}));
